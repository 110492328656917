import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserDetailsViewModel, VisitDetailsViewModel } from 'data';

export interface CustomVisitDetailsViewModel extends VisitDetailsViewModel {
  primaryInsurance: any;
  secondaryInsurance: any;
  tertiaryInsurance: any;
}

@Component({
  selector: 'lib-visit-details',
  templateUrl: './visit-details.component.html',
  styleUrls: ['./visit-details.component.scss']
})
export class VisitDetailsComponent implements OnInit {
  @Input()
  visit: CustomVisitDetailsViewModel;
  billProcedure: any[];

  @Input()
  viewOnly: boolean;

  @Input()
  userInfo: UserDetailsViewModel

  @Output() visitAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() editProcedure: EventEmitter<string> = new EventEmitter<string>();
  @Output() reverseProcedure: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {
    this.billProcedure = new Array(this.visit?.procedureTransactions?.length ?? 0);
    for (let i = 0; i < this.billProcedure.length; i++) {
      this.billProcedure[i] = true;
    }
  }
  notImplemented() {
    throw new Error('Feature not implemented');
  }
  billProcedureChanged($event) {
    this.billProcedure[$event.procedureNumber-1] = $event.bill;
  }

  trackById(index: number, item: any) {
    return item.id
  }
}
