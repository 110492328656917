import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ColumnDefinition } from 'components';
import { of } from 'rxjs';
import { catchError, map, take, filter } from 'rxjs/operators';
import { BaseReportGridSource } from '../base-report-grid-source';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { saveAs } from 'file-saver';
import { getSelectedDataSetId } from 'src/app/core/state/data-set/data-set.selectors';
import { Store } from '@ngrx/store';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class FinancialClassActivityReportService extends BaseReportGridSource<any> {
  dataSetId$ = this.baseStore.select(getSelectedDataSetId).pipe(filter((cId) => !!cId));
  dataSetId;
  constructor(private reportService: ReportWrapperService, @Inject(LOCALE_ID) private locale: string, private baseStore: Store<any>) {
    super();
    this.dataSetId$.subscribe((dataSetIdFromStore) => {
      this.dataSetId = dataSetIdFromStore;
    });
  }
  datePipe: DateDisplayPipe = new DateDisplayPipe(this.locale);
  initialize(query: any, columnDefinitions: ColumnDefinition[]) {
    this.gridColumns = columnDefinitions;
    const request = {
      slug: query.slug,
      datasetId: this.dataSetId,
      dateRangeStart: this.datePipe.transform(query.dateRangeStart),
      dateRangeEnd: this.datePipe.transform(query.dateRangeEnd),
      locationIds: query.locationIds?.join(',') ?? '',
      financialClassIds: query.financialClassIds?.join(',') ?? '',
      reportFormat: query.reportFormat,
      textParameters: this.textParameters
    };
    this.loadInitialData(
      this.reportService.apiV1ReportPost(request).pipe(
        map((x) => {
          this.textParameters['User'] = x.processedBy;
          this.reportExecutionId = x.reportExecutionId;
          return x.data.map((data) => {
            return { ...data };
          });
        }),
        catchError(() => of([]))
      )
    );
  }
}
