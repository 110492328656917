import { AfterViewInit, ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators, FieldSelectOption, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of, pipe } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { EobWrapperService } from 'src/app/core/services/service-wrappers/eob-wrapper.service';
import { PatientLookupService } from 'src/app/core/services/lookup/patient-lookup.service';
import { WriteOffLookupService } from 'src/app/core/services/lookup/write-off-lookup.service';
import { StatementMessageLookupService } from 'src/app/core/services/lookup/statement-message-lookup.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Console } from 'console';
import { EobCodesLookupService } from '../../../../core/services/lookup/eob-codes-lookup.service';

@Component({
  selector: 'app-eob-codes-dialog',
  templateUrl: './eob-codes-dialog.component.html',
  styleUrls: ['./eob-codes-dialog.component.scss'],
  providers: [DatePipe]
})
export class EobCodesDialogComponent implements OnInit, AfterViewInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  sameCode = false;
  duplicateCode = false;
  eobCodeId;
  localResults;
  duplicateResponse = '';
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required,
            selectionChanged: (event) => {
              this.nameChange(event);
            }
          },
          {
            label: 'Description',
            name: 'name',
            type: 'text',
            class: 'form-span-7',
            validators: Validators.required
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            class: 'form-span-2',
            initial: true
          },
          {
            label: 'Write Off Procedure',
            name: 'doWriteOff',
            type: 'select',
            options: ['Yes', 'No', {label: 'EOB Amount', value:'EOB_Amount'}],
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Write Off Adjustment Code',
            name: 'adjustmentCodeId',
            type: 'select',
            apiService: this.writeOffLookupService,
            class: 'form-span-4'
          },
          {
            label: 'Statement Message',
            name: 'statementMessage',
            type: 'text',
            class: 'form-span-5'
          },
          {
            label: 'Bill Next',
            name: 'billNext',
            type: 'select',
            options: ['Yes', 'No'],
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Move To Patient Responsible',
            name: 'patientResponsible',
            type: 'select',
            options: [{ label: 'Yes', value: true }, { label:'No', value:false}],
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Effective From',
            name: 'effectiveFrom',
            type: 'date',
            class: 'form-span-6',
            datepickerChanged: (event) => {
              this.validatorEffectiveToDate(event);
              this.sameNameTo(event);
            }
          },
          {
            label: 'Effective To',
            name: 'effectiveTo',
            type: 'date',
            class: 'form-span-6',
            customErrorMessage: 'Effective From date.',
            datepickerChanged: (event) => {
              this.validatorEffectiveToDate(event);
              this.sameNameTo(event);
            }
          },
          {
            label: 'Suppress Secondary',
            name: 'suppressSecondary',
            type: 'checkbox',
            class: 'form-span-2',
            initial: false
          },
          {
            label: 'Denied',
            name: 'isDenied',
            type: 'checkbox',
            class: 'form-span-2',
            initial: false
          },
          {
            label: 'Underpaid',
            name: 'isUnderPaid',
            type: 'checkbox',
            class: 'form-span-2',
            initial: false
          },
          {
            label: 'Follow Up',
            name: 'isFollowUp',
            type: 'checkbox',
            class: 'form-span-2',
            initial: false
          },
          {
            label: 'Follow Up Days',
            name: 'followUpDays',
            type: 'text',
            class: 'form-span-1',
            validators: [CustomValidators.numberValidator]
          },
        ]
      },
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<EobCodesDialogComponent>,
    private service: EobWrapperService,
    private dialogService: DialogService,
    public lookup: EobCodesLookupService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private patientLookupService: PatientLookupService,
    private writeOffLookupService: WriteOffLookupService,
    private statementMessageLookupService: StatementMessageLookupService,
    private cd: ChangeDetectorRef,

  ) {
    this.eobCodeId = data?.eobCodeId;
    this.viewOnly = data?.viewOnly;
    const isEdit = this.eobCodeId && this.eobCodeId !== '';
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }
  

  ngOnInit(): void {
    this.setupForm();

    this.adjustmentCodeValidator();
    if (this.eobCodeId && this.eobCodeId !== '') {
      this.service
        .apiV1EobcodeIdGet(this.eobCodeId)
        .pipe(first())
        .subscribe((result) => {
         
          this.formGroup.patchValue(result);
          if (result.inactive === true) {
            this.formGroup.get('active').setValue(false)
          }
          if (result.inactive === false) {
            this.formGroup.get('active').setValue(true)
          }
    
          if (result.isDenied === "No") {
            this.formGroup.get('isDenied').setValue(false);
          }
          if(result.isDenied === "Yes") {
            this.formGroup.get('isDenied').setValue(true);
          }
         
        });
    }
  }
  ngAfterViewInit(): void {
    this.adjustmentCodeValidator();
    //this.transactionMemoValidator();
    this.cd.detectChanges();
   
  }
  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  //throws error if name and date range values already exist
  nameChange(event) {
    if (event !== '') {
      let to = moment(new Date(this.datePipe.transform(this.formGroup.get('effectiveTo').value, 'MM/dd/yyyy')));
      //searches lookup service to query backend for current user name field input this is not exact match searhc so it brings back results that include the current user field input
      this.lookup.searchEOBmaint(event).subscribe((result: any[]) => {
        this.localResults = result;
        //this result.find searches the results array from the lookup search to see if theres an exact match to the current user input
        let duplicate = result.find((o) => {
          return o.value?.code.toLowerCase() === event.toLowerCase()
        })
        if (duplicate !== undefined) {
          //sets same as code variable to true and then checks the date varibales with the sameNameTo
          this.sameCode = true
          this.sameNameTo(to, event)
        }
        else {
          this.sameCode = false
          this.duplicateCode = false
        }

      });
    }

  }

  //updates validity of name input if from date field fall within already existing range
  sameNameFrom(date) {
    if (this.formGroup.get('code').value !== null) {
      this.formGroup.get('effectiveTo').setValue(null);
    }
  }

  //updates validity of name input if To date field fall within already existing range
  sameNameTo(date, name: string = this.formGroup.get('code').value) {
    if (name > '') {
      this.duplicateCode = false;
      let dateFrom = this.datePipe.transform(this.formGroup.get('effectiveFrom').value, 'yyyy/MM/dd');
      if (dateFrom === null){dateFrom = '2000/01/01'}
      let dateTo = this.datePipe.transform(this.formGroup.get('effectiveTo').value, 'yyyy/MM/dd');
      if (dateTo === null){dateTo = '2099/12/31'}
      this.localResults.forEach((eobCode) => {
        if (eobCode.value.code.toLowerCase() === name.toLowerCase())
        {
          let sameNameFromDate = this.datePipe.transform(eobCode.value.effectiveFrom, 'yyyy/MM/dd');
          let sameNameToDate = this.datePipe.transform(eobCode.value.effectiveTo, 'yyyy/MM/dd');
          if (this.eobCodeId !== eobCode.value.id) {  // don't test for the current eob Code 
            if (
              (dateFrom >= sameNameFromDate && dateFrom <= sameNameToDate) ||
              (dateTo >= sameNameFromDate && dateTo <= sameNameToDate) ||
              (dateFrom < sameNameFromDate && dateTo > sameNameToDate) // overlapping - superseding
            ) {
              this.duplicateCode = true;
              this.duplicateResponse = 'This EOB Code already exists with overlapping Effective Dates.' 
            }
          }
        }
      });
    }
  }

  //validates that effective To Date is Greater that effective From Date
  validatorEffectiveToDate(date) {
    const from = new Date(this.formGroup.get('effectiveFrom').value);
    const to = new Date(this.formGroup.get('effectiveTo').value);
    if (to < from && from !== null) {
      this.formGroup.get('effectiveTo').setErrors({ greaterDate: true });
      this.formGroup.get('effectiveFrom');
    } else {
      this.formGroup.get('effectiveTo').setErrors(null);
      this.formGroup.get('effectiveFrom').setErrors(null);
      this.formGroup.get('effectiveFrom').updateValueAndValidity();
    }
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        code: this.formGroup?.get('code')?.value,
        name: this.formGroup?.get('name')?.value,
        inactive: this.formGroup?.get('active')?.value === true ? false : true,
        doWriteOff: this.formGroup?.get('doWriteOff')?.value,
        adjustmentCodeId: this.formGroup?.get('adjustmentCodeId')?.value,
        statementMessage: this.formGroup?.get('statementMessage')?.value,
        billNext: this.formGroup?.get('billNext')?.value,
        patientResponsible: this.formGroup?.get('patientResponsible')?.value,
        effectiveFrom: this.formGroup?.get('effectiveFrom')?.value,
        effectiveTo: this.formGroup?.get('effectiveTo')?.value,
        //createMemo: this.formGroup?.get('createMemo')?.value,
        //memoText: this.formGroup?.get('memoText')?.value,
        isDenied: this.formGroup?.get('isDenied')?.value === true ? 'Yes' : 'No',
        isFollowUp: this.formGroup?.get('isFollowUp')?.value,
        followUpDays: this.formGroup?.get('followUpDays')?.value ? this.formGroup?.get('followUpDays')?.value : "0",
        suppressSecondary: this.formGroup?.get('suppressSecondary')?.value,
        isUnderPaid: this.formGroup?.get('isUnderPaid')?.value
      };
      
      let saveObservable: Observable<any>;
      let notification;
      if (this.eobCodeId) {
        notification = 'EOB Code Updated';
        saveObservable = this.service
          .apiV1EobCodePatchIdPath(this.eobCodeId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'EOB Code Added';
        saveObservable = this.service.apiV1EobCodeAddPost(formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving EOB Code')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }

  adjustmentCodeValidator() {
    let input = this.formGroup.get('doWriteOff');
    let output = this.formGroup.get('adjustmentCodeId');
    if (input.value !== 'No') {
      output.setValidators([Validators.required]);
      output.updateValueAndValidity();
      if (this.formGroup.invalid) {
        this.saving = false
      }
    }
    input?.valueChanges?.subscribe((writeOffCharge) => {
      if (writeOffCharge !== 'No') {
        output.setValidators([Validators.required]);
        output.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false
        }
      } else {
        output.removeValidators([Validators.required])
        output.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false
        }
      }
   
    });
  }

  /*transactionMemoValidator() {
   let input = this.formGroup.get('createMemo');
    let output = this.formGroup.get('memoText');
    if (input.value === true) {
      output.setValidators([Validators.required]);
      output.updateValueAndValidity();
      if (this.formGroup.invalid) {
        this.saving = false
      }
    }
    input?.valueChanges?.subscribe((memo) => {
      if (memo) {
        output.setValidators([Validators.required]);
        output.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false
        }
      } else {
        output.setValidators([]);
        output.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false
        }
      }
    });
  }*/
}
