<div class="visit-details mat-elevation-z4">
  <header class="visit-details__details-header">
    <h3>Visit {{ visit?.referenceNumber }}</h3>
    <div class="visit-details__details-buttons">
      <button mat-button (click)="visitAction.emit({ action: 'cancel' })">Close</button>
      <button mat-stroked-button color="primary" (click)="visitAction.emit({ action: 'edit' })" *ngIf="!viewOnly">
        Edit Visit
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="visitAction.emit({ action: 'bill', data: this.billProcedure })"
        *ngIf="!viewOnly"
      >
        Bill Visit
      </button>
    </div>
  </header>
  <lib-visit-information [visit]="visit"></lib-visit-information>
  <div class="visit-details__visit-body">
    <h3 class="procedure-title">Procedure Information</h3>
    <div class="procedure-cards">
      <lib-procedure-details
        *ngFor="let procedure of visit?.procedureTransactions; let i = index; trackBy: trackById"
        [procedure]="procedure"
        [procedureNumber]="i + 1"
        (editProcedure)="visitAction.emit({ action: 'edit' })"
        (reverseProcedure)="reverseProcedure.emit($event)"
        (billProcedureChange)="billProcedureChanged($event)"
        class="visit-details__procedure-entry"
        [viewOnly]="viewOnly"
        [userInfo]="userInfo"
      >
      </lib-procedure-details>
    </div>
    <ng-container>
      <h3 class="procedure-title">Visit Payments</h3>
      <span *ngIf="visit?.arTransactions.length === 0"> No Payment Activity Found. </span>
      <table class="payment-summary-table" *ngIf="visit?.arTransactions.length > 0">
        <tr>
          <th>Post Date</th>
          <th>Batch</th>
          <th>Check</th>
          <th>Allowed</th>
          <th>Paid</th>
          <th>Adjusted 1</th>
          <th>Adjusted 2</th>
        </tr>
        <tr *ngFor="let transaction of visit?.arTransactions; trackBy: trackById">
          <td>{{ transaction?.postingDate | dateDisplay }}</td>
          <td>{{ transaction?.batch?.batchNumber || '--' }}</td>
          <td>{{ transaction?.checkNumber || '--' }}</td>
          <td>{{ transaction?.allowed | currency }}</td>
          <td>{{ transaction?.paid | currency }}</td>
          <td>{{ transaction?.adjusted | currency }}</td>
          <td>{{ transaction?.adjusted2 | currency }}</td>
        </tr>
      </table>
    </ng-container>
  </div>
</div>
