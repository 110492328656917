import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FinancialClassWrapperService } from '../service-wrappers/financial-class-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialClassesLookupService extends ApiAutoCompleteService {
  financialClasses$ = this.service.apiV1FinancialClassAllPost({ generalSearch: '', pageSize: 999 }).pipe(shareReplay(1));

  constructor(private service: FinancialClassWrapperService) {
    super();
    this.dynamicSearch = true;
  }

  bIncludeInactive: boolean = true;

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.code}-${result.name}`,
      shortLabel:result.code,
      inactive: result.inactive
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.financialClasses$.pipe(
        map((results) => this.sortByCode(results.data)),
        map((results) =>  results.map(this.mapRow).filter((x: any) => !x.inactive || this.bIncludeInactive))
      );
    }
    return this.service.apiV1FinancialClassAllPost({ generalSearch: value, pageSize: 100 }).pipe(
      map((results) => this.sortByCode(results.data)),
      map((results) =>  results.map(this.mapRow).filter((x: any) => !x.inactive || this.bIncludeInactive))
    );
  }

  sortByCode(fc) {
    return fc.sort((a, b) => (a.code < b.code ? -1 : 1));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1FinancialClassIdGet(id).pipe(map(this.mapRow));
  }

  loadList(ids: string[]): Subject<string[]> {
    let results: string[] = []
    let obs: Subject<string[]> = new Subject<string[]>();
    ids.forEach((i, index) => {
     return this.service.apiV1FinancialClassIdGet(i).pipe(map(this.mapRow)).subscribe((result) => {
       results.push(result.label)

       if (ids.length > 0) {
         if (index === ids.length-1) {
           obs.next(results)
         }
       }

      })

    })
    return obs
  }
}
