import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { BodyClasses, ColumnSizes } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { CollectionRatioReportService } from './collection-ratio-report.service';
import { FinancialClassesLookupService, ProcedureCodesLookupService } from '@core/services/lookup';

export const CollectionRatioReport: Report = {
  id: 'collection-ratio',
  label: 'Collection Ratio Report',
  emptyDialogContent: false,
  textCriteria: { 'Date Range Type': '', 'Date Range Start': '', 'Date Range End': '', 'Insurance Level': '', 'Financial Class': '', 'Procedure Code': ''},
  getReportTitle: () => {
    return CollectionRatioReport.label + ' - ' + CollectionRatioReport.textCriteria['Report Date']
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Date Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-6',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            CollectionRatioReport.textCriteria['Report Date Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Report Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            CollectionRatioReport.textCriteria['Date Range Start'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            CollectionRatioReport.textCriteria['Date Range End'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Insurance Level',
          name: 'insuranceLevel',
          class: 'form-span-6',
          type: 'select',
          multiple: true,
          options: [
            'Primary',
            'Secondary',
            'Tertiary'
          ],
          selectionChanged: (event) => {
            CollectionRatioReport.textCriteria['Insurance Level'] = event.source.triggerValue;
          }
        },
        {
          label: 'Financial Class',
          name: 'financialClassIds',
          class: 'form-span-6',
          type: 'select',
          multiple: true,
          apiService: injector.get(FinancialClassesLookupService),
          selectionChanged: (event) => {
            CollectionRatioReport.textCriteria['Financial Class'] = event.source.triggerValue;
          }
        },
        {
          label: 'Procedure Code',
          name: 'procedureIds',
          class: 'form-span-6',
          type: 'select',
          multiple: true,
          apiService: injector.get(ProcedureCodesLookupService),
          selectionChanged: (event) => {
            CollectionRatioReport.textCriteria['Procedure Code'] = event.source.triggerValue;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(CollectionRatioReportService),
      columnDefinitions: [
        {
          id: 'Category',
          displayKey: 'Category',
          headerText: 'Category',
          displayOrder: 0,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Volume',
          displayKey: 'Volume',
          headerText: 'Volume',
          displayOrder: 1,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Amount',
          displayKey: 'Amount',
          headerText: 'Amount',
          displayOrder: 2,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true,
          formatter: (x) => {
            if (!isNaN(x)) {
              return moneyFormat.format(x);
            }
            else {
              return x;
            }
          }
        },
        {
          id: 'Fiscal YTD',
          displayKey: 'YTD Volume',
          headerText: 'Fiscal YTD Volume',
          displayOrder: 3,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Fiscal YTD Amount',
          displayKey: 'YTD Amount',
          headerText: 'Fiscal YTD Amount',
          displayOrder: 4,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true,
          formatter: (x) => {
            if (!isNaN(x)) {
              return moneyFormat.format(x);
            }
            else {
              return x;
            }
          }
        }
      ]
    };
  }
};
