import { Observable, of } from 'rxjs';
import { FieldSelectOption } from '../models';
import { CodeWrapperService } from '@core/services/service-wrappers/code-wrapper.service';
import { map } from 'rxjs/operators';

export class ApiAutoCompleteService {
  public dynamicSearch = false;
  constructor() {
  }
  search(value: string): Observable<FieldSelectOption[]> {
    throw new Error('Search method not implemented.');
  }
  load(id: string | number): Observable<FieldSelectOption> {
    throw new Error('Load method not implemented.');
  }
   
  loadByIds(ids: string[]): Observable<FieldSelectOption[]> {
    let retval: FieldSelectOption[]  = [];
    return of(retval);
  }
  
  sortCategories(categories: FieldSelectOption[]): FieldSelectOption[] {
    return categories.sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  filteredSearch(value: string, filterProperty:string, filterObject:any): Observable<FieldSelectOption[]> {
    throw new Error('Load method not implemented.');
  }

}
