/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureDetailsViewModel } from './procedureDetailsViewModel';
import { LocationDetailsViewModel } from './locationDetailsViewModel';
import { ARTransactionDetailModel } from './aRTransactionDetailModel';
import { NationalDrugCodeViewModel } from './nationalDrugCodeViewModel';
import { CodeViewModel } from './codeViewModel';
import { BillingDetailHolding } from './billingDetailHolding';
import { ProcedureDiagnosisCodeViewModel } from './procedureDiagnosisCodeViewModel';
import { ProviderDetailsViewModel } from './providerDetailsViewModel';

export interface ProcedureTransactionDetailsViewModel {
  id?: string;
  sequence?: number;
  datasetId?: string;
  visitId?: string;
  procedureId?: string;
  nocProcedureDescription?: string | null;
  nationalDrugCodeId?: string | null;
  attendingProviderId?: string | null;
  referringProviderId?: string | null;
  orderingProviderId?: string | null;
  primaryProviderId?: string | null;
  supervisingProviderId?: string | null;
  serviceFromDate?: string;
  serviceToDate?: string;
  postDate?: string;
  placeOfServiceCodeId?: string;
  locationId?: string;
  modifier1Id?: string | null;
  modifier2Id?: string | null;
  modifier3Id?: string | null;
  modifier4Id?: string | null;
  quantity?: number;
  accessionNumber?: string | null;
  drugUnit?: string | null;
  drugQuantity?: number | null;
  drugAmount?: number | null;
  holdPatientBilling?: boolean | null;
  holdInsuranceBilling?: boolean | null;
  isReversed?: boolean;
  drugCode?: string | null;
  prescriptionNumber?: string | null;
  insuranceAuthNumber?: string | null;
  createdBy?: string | null;
  enteredBy?: string | null;
  postedBy?: string | null;
  procedureAmount?: number;
  balance?: number;
  patientBalance?: number;
  insuranceBalance?: number;
  claimComment?: string | null;
  allowAmountUpdate?: boolean;
  chargeGroup?: number | null;
  diagnoses?: Array<ProcedureDiagnosisCodeViewModel> | null;
  arTransactionDetails?: Array<ARTransactionDetailModel> | null;
  billingDetails?: Array<BillingDetailHolding> | null;
  attendingProvider?: ProviderDetailsViewModel;
  referringProvider?: ProviderDetailsViewModel;
  orderingProvider?: ProviderDetailsViewModel;
  primaryProvider?: ProviderDetailsViewModel;
  supervisingProvider?: ProviderDetailsViewModel;
  location?: LocationDetailsViewModel;
  procedure?: ProcedureDetailsViewModel;
  placeOfServiceCode?: CodeViewModel;
  modifier1?: CodeViewModel;
  modifier2?: CodeViewModel;
  modifier3?: CodeViewModel;
  modifier4?: CodeViewModel;
  nationalDrugCode?: NationalDrugCodeViewModel;
  readonly currentlyBilled?: boolean;
  readonly currentlyBilledCarriers?: string | null;
  readonly previouslyBilled?: boolean;
}
